<template>
  <div class="app-body">
    <div class="wrapper w-100 m-3">
      <div class="animated fadeIn">
        <b-row class="justify-content-center">
          <b-col xl="8" lg="10">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <i class="fa fa-users"></i><strong> Invitations</strong>
                <info-message>
                  Vous pouvez inviter une personne tierce à visiter l'application PIA. Cliquez sur le bouton Inviter une personne et saisissez l'email du destinataire.
                </info-message>
                <div class="card-header-actions">
                  <b-button variant="primary" @click="newInvitationModal = true">
                    <i class="fa fa-user-plus"></i>
                    Inviter une personne
                  </b-button>

                  <b-modal title="Inviter une personne" class="modal-primary" v-model="newInvitationModal"
                           @hide="onInvitationModalClose">
                    <description-message>
                      Entrez l'adresse email de la personne que vous invitez à visiter notre plateforme.
                    </description-message>
                    <b-form>
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

                      <c-input container-class="mb-3 mt-3" label="Adresse email" placeholder="Ex: bob@gmail.com"
                               v-model="invitationEmail" :state="invitationEmailState">
                        Veuillez saisir une adresse email valide
                      </c-input>
                    </b-form>

                    <div slot="modal-footer" class="w-100 text-center">
                      <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitInvitation"
                                      :fetching="fetchingNewInvitation">
                        Inviter
                      </button-spinner>
                      <b-button variant="secondary" @click="newInvitationModal = false" class="ml-2">
                        Annuler
                      </b-button>
                    </div>
                  </b-modal>
                </div>
              </div>
              <div class="text-center" v-if="fetchingInvitationList">
                <b-spinner></b-spinner>
                <br>
                Chargement...
              </div>
              <b-row class="exploitations mt-1" v-else>
                <c-table :table-data="invitations" :fields="fields" :actions="actions" :per-page=5 striped outlined>
                  <template slot="empty">
                    <p class="text-center">Vous n'avez invité personne.</p>
                  </template>
                </c-table>
              </b-row>

            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {Regex, Api, Toast, Storage, String} from "../../helpers"
  import VueTelInput from 'vue-tel-input'
  import BCardText from "bootstrap-vue/src/components/card/card-text";

  export default {
    name: 'Invitations',
    title: 'PIA - Invitations',
    data() {
      return {
        // Invitations
        fetchingInvitationList: false,
        invitations: [],
        fields: [
          {key: 'email', label: 'Adresse e-mail', sortable: true},
          {key: 'updatedAt', label: 'Dernière relance', sortable: true},
          {key: 'status', label: 'Statut', sortable: true},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'success', icon: 'icon-refresh', title: 'Relancer', handler: this.resendInvitation},
        ],
        newInvitationModal: false,
        invitationEmail: '',
        submittedNewInvitation: false,
        fetchingNewInvitation: false,

        error: null,
        fetching: false
      }
    },
    created() {
      this.fetchingInvitationList = true
      Api.get('/user/invitation/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.invitations = res.data.data.map(this.formatInvitation)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingInvitationList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      invitationEmailState() {
        return !this.submittedNewInvitation || Regex.isEmail(this.invitationEmail) ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      formatInvitation(invitation) {
        const s = {
          PENDING: {
            status: 'En Attente...',
            statusVariant: 'warning'
          },
          ACCEPT: {
            status: 'Acceptée',
            statusVariant: 'success'
          },
          DECLINE: {
            status: 'Déclinée',
            statusVariant: 'danger'
          }
        }
        return {
          ...invitation,
          statusCode: invitation.status,
          ...s[invitation.status]
        }
      },
      onInvitationModalClose() {
        this.invitationEmail = ''
        this.submittedNewInvitation = false
        this.error = null
        this.fetchingNewInvitation = false
      },
      validInvitation() {
        return Regex.isEmail(this.invitationEmail)
      },
      onSubmitInvitation() {
        this.submittedNewInvitation = true
        if (!this.validInvitation())
          return

        this.fetchingNewInvitation = true
        Api.post('/user/invitation/create', {
          email: this.invitationEmail
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.invitations.push(this.formatInvitation(res.data.data))
              this.newInvitationModal = false

              Toast.success('Invitation créée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(e => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewInvitation = false
          })
      },
      resendInvitation(invitation) {
        if (invitation.statusCode !== 'PENDING') {
          Toast.show("L'utilisateur a déjà réagi à cette invitation.")
          return
        }

        const loadingToast = Toast.loading()
        Api.post('/user/invitation/resend', {
          invitationId: invitation.id
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null

              Toast.success('La relance a été effectuée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(e => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            loadingToast.goAway(100)
          })
      },
    }
  }
</script>

<style scoped>

</style>
